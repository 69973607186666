import { Injectable } from '@angular/core'
import { AlertController, ToastController, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable()
export class GlobalVariable {

  baseUrl = "http://livesports99.com/application/api/v1";
  global_userType: any;
  global_username: any;

  first_name: any;
  last_name: any;
  company_name: any;
  phone: any;
  city: any;
  state: any;
  email: any;
  
  auth_token: any;
  vehicle = [];
  loads = [];
  material = [];
  weight = [];
  notifiCount: any;
  serverError = 'Server Error';

  constructor(public alertCtrl: AlertController, public toastController: ToastController, public loadingCtrl: LoadingController, private storage: Storage) {
    console.log("Hello Global");

    this.auth_token = localStorage.getItem("auth_token");
    console.log("Hello Global", this.auth_token);
    this.global_username = localStorage.getItem("username");
    this.global_userType = localStorage.getItem("userType");
  }

  Question_Array = [
    {
      'Title': 'How Does Go Truck Works?',
      Content: `We have simplified the process of finding trucks or load to just one step. Simply signup, provide of your load/truck and wait for bid confirmation. once a match is found, you are notified immediately by email/SMS/Call.`
    },
    {
      'Title': 'Who Can Use Go Truck?',
      Content: `Truck owners, truck users, companies, business, brokers, agents and any one that has need to transport its goods from one place to another.`
    },
    {
      'Title': 'How would I know my approximate trip distance and my approximate bill amount?',
      Content: `Your approximate trip distance & approximate bill amount will be notified to you during your booking process.`
    },
    {
      'Title': 'Would I get any manpower for loading /unloading & packing service?',
      Content: `All services confirmed through trukky.com only includes transportation of goods and for any manpower help you need to co-ordinate with assigned operator/driver.`
    },
    {
      'Title': 'How does Go Truck benefit me?',
      Content: `Approach more customers/vendors with our nationwide database, and expand your customer base. Access detailed job/truck information anytime without having to place a single call.
      Find return load and make the most of every trip. You and your potential customer are notified instantly about the job/truck availability, even when you are offline.`
    },
    {
      'Title': 'What kinds of Truck/Loads are posted on Go Truck?',
      Content: `In order to better serve our users we provide multiple types of loads/trucks. We continuously update the type of load/trucks. However, users can put other type of Truck/Load under “Other” category. Hence, all types of Truck/Load can be posted.`
    },
    {
      'Title': 'Why we are different?',
      Content: `We at Go Truck caters to the logistics industry with a different approach and a new zeal as compared to other websites. We provide a common platform for the transporters, truck drivers, customers and other related entities to connect with each other. We do not differentiate between problems. If you have an issue, we will assist you. No other site boasts of so many facilities as provided by Go Truck.`
    },
    {
      'Title': 'Why choose us?',
      Content: `Thousands of members post and search for loads & trucks on our website which gives you a variety to choose amongst the range of competitors. At Go Truck we are impartial and we have made the site self-explanatory. Every information related to us or the services we provide is available on the site. We provide you correct, relevant and adequate information which help you to increase your business.`
    },
    {
      'Title': 'Do I need authority to use Go Truck?',
      Content: `Yes, first of all you have register on our website. After that you are have the authority to use Go Truck.`
    },
    {
      'Title': 'What are your customer service hours?',
      Content: `We are available from 9.00 am to 5.00 pm on Monday to Saturday as Sunday is an off for our employees. We may be available beyond this duration as per the need. You can always write to us as we make it a point to reply to all our mails. We shall try our best to make us available to help you.`
    },
    {
      'Title': 'Who can post trucks to the site?',
      Content: `The person who has registered with us can post truck on Go Truck.`
    },
    {
      'Title': 'Is my information is secure with you?',
      Content: `Yes! Our website is taking the best security measures in place to protect the loss, misuse and alteration of the information under our control.`
    }
  ];

  async toastMsg(msg) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'bottom',
      color: "green",
      duration: 4000
    });
    toast.present();
  }

  async errorToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'bottom',
      color: "danger",
      duration: 4000
    });
    toast.present();
  }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Subtitle',
      message: 'This is an alert message.',
      buttons: ['OK']
    });
    await alert.present();
  }

}