import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { ModalController, NavController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { EmailValidator } from '../validators/email';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Storage } from '@ionic/storage';

import { ServerService } from '../server.service';
import { GlobalVariable } from '../../app/global';
import { browser } from 'protractor';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  @Input() estimate: boolean;
  submitAttempt: boolean = false;
  alertMsg: any;
  loginForm: FormGroup;

  constructor(navParams: NavParams, public formBilder: FormBuilder, public global: GlobalVariable, private server: ServerService, public loadingCtrl: LoadingController, public navCtrl: NavController, public alertController: AlertController, private modalCtrl: ModalController, private storage: Storage) {
    this.estimate = navParams.get('estimate');
  }

  ngOnInit() {
    this.loginForm = this.formBilder.group({
      email: ['', Validators.compose([Validators.required, EmailValidator.isValid])],
      password: ['', Validators.required]
    });
  }

  async signIn(formData: any) {
    if (!this.loginForm.valid) {
      this.submitAttempt = true;
      console.log(' Some values were not given or were incorrect, please fill them');
    } else {
      let loading = await this.loadingCtrl.create({
        message: 'Please wait...'
      });
      await loading.present();

      let res_signIn = this.server.login(this.loginForm.value);
      res_signIn.subscribe(res => {
        loading.dismiss();
        console.log("Login Res ", res);
        if (res.Good) {
          this.global.auth_token = res.authToken;
          this.global.global_username = res.user.name;
          this.global.global_userType = res.user.user_type;
          
          this.global.first_name = res.user.userdata.first_name;
          this.global.last_name = res.user.userdata.last_name;
          this.global.company_name = res.user.userdata.company_name;
          this.global.phone = res.user.userdata.phone;
          this.global.city = res.user.userdata.city;
          this.global.state = res.user.userdata.state;
          this.global.email = res.user.userdata.email;
          
          localStorage.setItem("auth_token", res.authToken);
          localStorage.setItem("username", res.user.name);
          localStorage.setItem("userType", res.user.user_type);

          localStorage.setItem("fname", res.user.userdata.first_name);
          localStorage.setItem("lname", res.user.userdata.last_name);
          localStorage.setItem("c_name", res.user.userdata.company_name);
          localStorage.setItem("phone", res.user.userdata.phone);
          localStorage.setItem("city", res.user.userdata.city);
          localStorage.setItem("state", res.user.userdata.state);
          localStorage.setItem("email", res.user.userdata.email);

          this.global.toastMsg('Login Successful');
          
          this.notifiCount();
          this.modalCtrl.dismiss();
          if (this.global.global_userType == 1) {
            this.navCtrl.navigateForward('/my-bookings');
          } else {
            // this.navCtrl.navigateForward('/my-bookings');
          }
        } else {
          this.global.errorToast(res.errors);
        }
      }, err => {
        loading.dismiss();
        this.global.errorToast(this.global.serverError);
      });
    }
  }

  async notifiCount() {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    const res_booking = this.server.notificationCount();
    res_booking.subscribe(res => {
      loading.dismiss();
      if (res.Good) {
        this.global.notifiCount = res.data.count;
        console.log('Res Count ', this.global.notifiCount);
      } else {
        this.global.errorToast(res.error);
      }
    }, err => {
      loading.dismiss();
      this.global.errorToast(this.global.serverError);
    });
  }

  async signUp() {
    this.modalCtrl.dismiss();
    this.navCtrl.navigateForward('/register');
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
