import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

import { GlobalVariable } from '../../app/global';
import { ServerService } from '../server.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public global: GlobalVariable, public navCtrl: NavController, private service: ServerService, private storage: Storage) {
    console.log("Constructor Auth Guard");
  }

  canActivate(): boolean {
    console.log("In Auth guard ", this.service.checkStorage());
    return this.service.checkStorage();
  }

}


