import { Component, OnInit } from '@angular/core';
import { Platform, ModalController, MenuController, NavController, LoadingController, PopoverController } from '@ionic/angular';
import { ActivatedRoute, NavigationExtras } from "@angular/router";

import { ServerService } from '../server.service';
import { GlobalVariable } from '../../app/global';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.page.html',
  styleUrls: ['./notification.page.scss'],
})
export class NotificationPage implements OnInit {
  notifications = [];

  constructor(public global: GlobalVariable, private server: ServerService, public loadingCtrl: LoadingController, public navCtrl: NavController, public popoverController: PopoverController) { }

  async ngOnInit() {
    this.notif();
  }

  async notif(){
    let loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    let res_notification = this.server.notification();
    res_notification.subscribe(res => {
      loading.dismiss();
      if (res.Good) {
        console.log("Res ", res.data);
        this.notifications = res.data;
      } else {
        this.global.errorToast(res.error);
      }
    }, err => {
      loading.dismiss();
      this.global.errorToast(this.global.serverError);
    });
  }

  details(id){
    this.popoverController.dismiss();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: id
      }
    };
    this.navCtrl.navigateForward(['shipment-detail'], navigationExtras);
  }
}
