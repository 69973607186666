import { Component } from '@angular/core';

import { Platform, ModalController, MenuController, NavController, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { ServerService } from '../app/server.service';
import { GlobalVariable } from '../app/global';
import { Storage } from '@ionic/storage';

import { LoginPage } from '../app/login/login.page';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(private platform: Platform, private splashScreen: SplashScreen, private statusBar: StatusBar, private modalCtrl: ModalController, private menu: MenuController, public navCtrl: NavController, public global: GlobalVariable, public loadingCtrl: LoadingController, private server: ServerService, private storage: Storage) {
    this.initializeApp();
    console.error("App component Runs")
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();
      this.splashScreen.hide();

      this.global.auth_token = localStorage.getItem("auth_token");
      this.global.global_username = localStorage.getItem("username");
      this.global.global_userType = localStorage.getItem("userType");

      this.global.first_name = localStorage.getItem("fname");
      this.global.last_name = localStorage.getItem("lname");
      this.global.company_name = localStorage.getItem("c_name");
      this.global.phone = localStorage.getItem("phone");
      this.global.city = localStorage.getItem("city");
      this.global.state = localStorage.getItem("state");
      this.global.email = localStorage.getItem("email");

      if (this.global.auth_token) {
        this.notifiCount();
      }

    });
  }

  async logout() {
    let loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    let res_signOut = this.server.logout();
    res_signOut.subscribe(res => {
      loading.dismiss();
      console.log("Logout Res ", res);
      if (res.Good) {
        console.log("Logout Success");
        this.menu.close();
        this.global.auth_token = undefined;
        this.global.global_username = undefined;
        this.global.global_userType = undefined;

        localStorage.removeItem("auth_token");
        localStorage.removeItem("username");
        localStorage.removeItem("userType");

        localStorage.removeItem("fname");
        localStorage.removeItem("lname");
        localStorage.removeItem("c_name");
        localStorage.removeItem("phone");
        localStorage.removeItem("city");
        localStorage.removeItem("state");
        localStorage.removeItem("email");

        this.navCtrl.navigateRoot('/home');
      } else {
        this.global.errorToast('Something went wrong');
      }
    }, err => {
      loading.dismiss();
      this.global.errorToast(this.global.serverError);
    });
  }

  async notifiCount() {
    let loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    let res_booking = this.server.notificationCount();
    res_booking.subscribe(res => {
      loading.dismiss();
      if (res.Good) {
        this.global.notifiCount = res.data.count;
        console.log("Res Count ", this.global.notifiCount);
      } else {
        this.global.errorToast(res.error);
      }
    }, err => {
      loading.dismiss();
      this.global.errorToast(this.global.serverError);
    });
  }

  async login() {
    this.menu.close();
    const modal = await this.modalCtrl.create({
      component: LoginPage,
      componentProps: { estimate: false },
      cssClass: 'login-modal-css'
    });

    return await modal.present();
  }

  openCustom(page) {
    this.menu.close();
    this.navCtrl.navigateForward(page);
  }

}
