import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ServerService } from '../app/server.service';
import { GlobalVariable } from '../app/global';
import { AgmCoreModule } from '@agm/core';
import { OrderModule } from 'ngx-order-pipe';
import { LoginPage } from '../app/login/login.page';
import { NotificationPage } from '../app/notification/notification.page';
import { RatingPage } from '../app/rating/rating.page';


import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { IonicStorageModule } from '@ionic/storage';

@NgModule({
  declarations: [
    AppComponent,
    // RegisterPage
    LoginPage,
    NotificationPage,
    RatingPage
  ],
  entryComponents: [
    // RegisterPage,
    LoginPage,
    NotificationPage,
    RatingPage
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OrderModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCnahpwY4LRTYlzEHnER3B_Y8NR1HzmrVE",
      libraries: ["places"]
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    ServerService,
    GlobalVariable,
    HTTP,
    HttpClient,
    NativeStorage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
