import { Component, OnInit } from '@angular/core';
import { Platform, ModalController, NavController, LoadingController, NavParams, Events } from '@ionic/angular';

import { ServerService } from '../server.service';
import { GlobalVariable } from '../../app/global';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.page.html',
  styleUrls: ['./rating.page.scss'],
})
export class RatingPage implements OnInit {
  message: any;
  rating: any = 0;
  id: any;

  constructor(public global: GlobalVariable, private server: ServerService, public loadingCtrl: LoadingController, private modalCtrl: ModalController, navParams: NavParams, public events: Events) { 
    this.id = navParams.get('id');
    console.log("id in modal ", this.id);
  }

  ngOnInit() {
  }

  rating_re(rating) {
    this.rating = rating;
  }

  async placeReview() {
    console.log("Rating ", this.rating);
    if (this.message == '' || this.rating == '') {
      this.global.errorToast('Please fill the form to submit review');
    } else {
      let loading = await this.loadingCtrl.create({
        message: 'Please wait...'
      });
      await loading.present();

      let res_review = this.server.postReview(this.id, this.rating, this.message);
      res_review.subscribe(res => {
        loading.dismiss();
        console.log("Res ", res);
        if (res.Good) {
          this.global.toastMsg(res.message);
          this.events.publish('reloadList');
          this.modalCtrl.dismiss();
          // this.loadRequests();
        } else {
          this.global.errorToast(res.errors);
        }
      }, err => {
        loading.dismiss();
        this.global.errorToast(this.global.serverError);
      });
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
