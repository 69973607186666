import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' }, 
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'about', loadChildren: './about/about.module#AboutPageModule' },
  { path: 'faq', loadChildren: './faq/faq.module#FaqPageModule' },
  { path: 'drivers', loadChildren: './drivers/drivers.module#DriversPageModule' },
  { path: 'contact', loadChildren: './contact/contact.module#ContactPageModule' },
  { path: 'howitwork', loadChildren: './howitwork/howitwork.module#HowitworkPageModule' },
  { path: 'estimate', loadChildren: './estimate/estimate.module#EstimatePageModule' },
  { path: 'shipper-request', loadChildren: './shipper-request/shipper-request.module#ShipperRequestPageModule', canActivate: [AuthGuard] },
  { path: 'shipment-detail', loadChildren: './shipment-detail/shipment-detail.module#ShipmentDetailPageModule', canActivate: [AuthGuard] },
  { path: 'my-bookings', loadChildren: './my-bookings/my-bookings.module#MyBookingsPageModule', canActivate: [AuthGuard] },
  { path: 'carrier-request', loadChildren: './carrier-request/carrier-request.module#CarrierRequestPageModule', canActivate: [AuthGuard] },
  { path: 'notification', loadChildren: './notification/notification.module#NotificationPageModule', canActivate: [AuthGuard] },
  { path: 'rating', loadChildren: './rating/rating.module#RatingPageModule', canActivate: [AuthGuard] },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule', canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
