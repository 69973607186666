import { Injectable } from '@angular/core';
import { GlobalVariable } from '../app/global';
import { Http, Response, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import { map } from "rxjs/operators";
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  requestOptions: any;
  authenticationState = new BehaviorSubject(false);

  constructor(private router: Router, public global: GlobalVariable, public http: Http, public hc: HttpClient, private storage: Storage) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    this.requestOptions = new RequestOptions({ headers: headers });

  }

  checkStorage(): any {
    this.global.auth_token = localStorage.getItem("auth_token");
    console.log("auth tokenene", this.global.auth_token)
    if (this.global.auth_token) {
      return true;
    }
    else {
      return false;
    }
  }

  login(form: any) {
    let url = this.global.baseUrl + "/login";

    let body = new URLSearchParams();
    body.append('email', form.email);
    body.append('password', form.password);

    console.log("Login API Body -> ", body);
    return this.http.post(url, body, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  logout() {
    let url = this.global.baseUrl + "/logout";

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    console.log("Logout API Body -> ", this.requestOptions);
    return this.http.post(url, '', this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  register(form, type) {
    let url = this.global.baseUrl + "/user";

    let body = new URLSearchParams();
    body.append('user_type', type);
    body.append('first_name', form.fname);
    body.append('last_name', form.lname);
    body.append('company_name', form.c_name);
    body.append('phone', form.phone);
    body.append('city', form.city);
    body.append('state', form.state);
    body.append('email', form.email);
    body.append('password', form.password);
    body.append('password_confirmation', form.password);
    body.append('ca_nc_num', form.ca_nc_num);

    console.log("Register API Body -> ", body);
    return this.http.post(url, body, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  updateProfile(form, type) {
    let url = this.global.baseUrl + "/update-profile";

    let body = new URLSearchParams();
    body.append('user_type', type);
    body.append('first_name', form.fname);
    body.append('last_name', form.lname);
    body.append('company_name', form.c_name);
    body.append('phone', form.phone);
    body.append('city', form.city);
    body.append('state', form.state);
    // body.append('email', form.email);
    body.append('password', form.password);
    body.append('password_confirmation', form.password);
    body.append('ca_nc_num', form.ca_nc_num);

    console.log("Update pro API Body -> ", body);
    return this.http.post(url, body, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  request(form) {
    let url = this.global.baseUrl + "/request/new";

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    let body = new URLSearchParams();
    body.append('ready_time', form.pickUp_readyTime);
    body.append('cutoff_time', form.pickUp_cutoffTime);
    body.append('deliver_after', form.deliver_time);
    body.append('deliver_cutoff_time', form.deliver_cutoffTime);
    body.append('origin_location_name', form.source_address);
    body.append('destination_location_name', form.destination_address);
    body.append('origin_address', form.source);
    body.append('origin_contact_name', form.name);
    body.append('origin_phone', form.phone);
    body.append('origin_email', form.email);
    body.append('destination_contact_name', form.receiver_name);
    body.append('destination_phone', form.receiver_phone);
    body.append('destination_email', form.receiver_email);
    body.append('destination_address', form.destination);
    body.append('pickup_date', form.pickUp_date);
    body.append('delivery_date', form.deliver_date);
    body.append('ref_num', String(form.ref_num));
    body.append('mo_num', String(form.mo_num));
    body.append('hawb_num', String(form.hawb_num));
    body.append('weight', String(form.weight));
    body.append('material', form.material);
    body.append('t_type', form.t_type);
    body.append('no_truck', form.no_truck);

    console.log("Request API -> ", body);
    return this.http.post(url, body, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  myRequest() {
    let url = this.global.baseUrl + "/request/shipper";

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    return this.http.get(url, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  carrierRequest() {
    let url = this.global.baseUrl + "/request/carrier/notified";

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    return this.http.get(url, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  bid(requestId, price, message) {
    let url = this.global.baseUrl + "/bid/" + requestId;

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    let body = new URLSearchParams();
    body.append('price', price);
    body.append('message', message);

    return this.http.post(url, body, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  requestDetail(id) {
    let url = this.global.baseUrl + "/request/view/" + id;

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    return this.http.get(url, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  myBookings() {
    let url;
    if (this.global.global_userType == 1) {
      url = this.global.baseUrl + "/booking/my-bookings/carrier";
    } else {
      url = this.global.baseUrl + "/booking/my-bookings/shipper";
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    return this.http.get(url, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  changeStatus(id, num) {
    let url;
    if (this.global.global_userType == 1) {
      url = this.global.baseUrl + "/request/status/" + id + "/" + num;
    } else {
      url = this.global.baseUrl + "/request/status/" + id + "/" + num;
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    return this.http.post(url, '', this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  postReview(id, rate, message) {
    let url = this.global.baseUrl + "/review/save/" + id;

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    let body = new URLSearchParams();
    body.append('rate', rate);
    body.append('comment', message);

    return this.http.post(url, body, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  getReview(id) {
    let url = this.global.baseUrl + "/review/retrieve/" + id;

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    // let body = new URLSearchParams();
    // body.append('rate', rate);
    // body.append('comment', message);

    return this.http.get(url, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  notification() {
    let url = this.global.baseUrl + "/notification";

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    return this.http.get(url, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  notificationCount() {
    let url = this.global.baseUrl + "/notification/unread/count";

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    return this.http.get(url, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  getMaterial() {
    let url = this.global.baseUrl + "/material";

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    return this.http.get(url, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  getWeight() {
    let url = this.global.baseUrl + "/weight";

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    return this.http.get(url, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  loadTypes() {
    let url = this.global.baseUrl + "/load-types";

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    return this.http.get(url, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  vehicleTypes() {
    let url = this.global.baseUrl + "/vehicle-type";

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;');
    headers.append('authToken', this.global.auth_token);
    this.requestOptions = new RequestOptions({ headers: headers });

    return this.http.get(url, this.requestOptions)
      .map(this.extractData)
      .do(this.logResponse)
      .catch(this.catchError)
  }

  private catchError(error: Response | any) {
    console.log("error ", error);
    return Observable.throw(error.error || "Server error");
  }

  private logResponse(res: Response | any) {
    console.log("server response", res);
    // console.log("server response - parsed", res.json());
  }

  private extractData(res: Response) {
    return res.json();
  }
}
